exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-food-and-drink-index-js": () => import("./../../../src/pages/food-and-drink/index.js" /* webpackChunkName: "component---src-pages-food-and-drink-index-js" */),
  "component---src-pages-food-and-drink-takeaway-js": () => import("./../../../src/pages/food-and-drink/takeaway.js" /* webpackChunkName: "component---src-pages-food-and-drink-takeaway-js" */),
  "component---src-pages-food-and-drink-the-vincent-bar-js": () => import("./../../../src/pages/food-and-drink/the-vincent-bar.js" /* webpackChunkName: "component---src-pages-food-and-drink-the-vincent-bar-js" */),
  "component---src-pages-health-and-beauty-house-of-evelyn-js": () => import("./../../../src/pages/health-and-beauty/house-of-evelyn.js" /* webpackChunkName: "component---src-pages-health-and-beauty-house-of-evelyn-js" */),
  "component---src-pages-health-and-beauty-index-js": () => import("./../../../src/pages/health-and-beauty/index.js" /* webpackChunkName: "component---src-pages-health-and-beauty-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-rooms-index-js": () => import("./../../../src/pages/our-rooms/index.js" /* webpackChunkName: "component---src-pages-our-rooms-index-js" */),
  "component---src-pages-policies-accessibility-policy-js": () => import("./../../../src/pages/policies/accessibility-policy.js" /* webpackChunkName: "component---src-pages-policies-accessibility-policy-js" */),
  "component---src-pages-policies-green-story-js": () => import("./../../../src/pages/policies/green-story.js" /* webpackChunkName: "component---src-pages-policies-green-story-js" */),
  "component---src-pages-policies-privacy-policy-js": () => import("./../../../src/pages/policies/privacy-policy.js" /* webpackChunkName: "component---src-pages-policies-privacy-policy-js" */),
  "component---src-pages-policies-responsible-guest-charter-js": () => import("./../../../src/pages/policies/responsible-guest-charter.js" /* webpackChunkName: "component---src-pages-policies-responsible-guest-charter-js" */),
  "component---src-pages-policies-sustainability-policy-js": () => import("./../../../src/pages/policies/sustainability-policy.js" /* webpackChunkName: "component---src-pages-policies-sustainability-policy-js" */),
  "component---src-pages-policies-terms-and-conditions-js": () => import("./../../../src/pages/policies/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-policies-terms-and-conditions-js" */),
  "component---src-pages-staff-js": () => import("./../../../src/pages/staff.js" /* webpackChunkName: "component---src-pages-staff-js" */),
  "component---src-pages-vouchers-js": () => import("./../../../src/pages/vouchers.js" /* webpackChunkName: "component---src-pages-vouchers-js" */),
  "component---src-pages-weddings-and-events-christmas-js": () => import("./../../../src/pages/weddings-and-events/christmas.js" /* webpackChunkName: "component---src-pages-weddings-and-events-christmas-js" */),
  "component---src-pages-weddings-and-events-events-js": () => import("./../../../src/pages/weddings-and-events/events.js" /* webpackChunkName: "component---src-pages-weddings-and-events-events-js" */),
  "component---src-pages-weddings-and-events-index-js": () => import("./../../../src/pages/weddings-and-events/index.js" /* webpackChunkName: "component---src-pages-weddings-and-events-index-js" */),
  "component---src-pages-weddings-and-events-meetings-js": () => import("./../../../src/pages/weddings-and-events/meetings.js" /* webpackChunkName: "component---src-pages-weddings-and-events-meetings-js" */),
  "component---src-pages-whats-on-js": () => import("./../../../src/pages/whats-on.js" /* webpackChunkName: "component---src-pages-whats-on-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-room-js": () => import("./../../../src/templates/room.js" /* webpackChunkName: "component---src-templates-room-js" */)
}

